body,
h1,
h2,
h3,
h4,
p,
span,
div {
  font-family: "GillSans-Light", "Gill Sans light", "Gill Sans MT", Calibri,
    "Trebuche MS", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-weight: lighter;
}

#root {
  min-height: 100vh;
  display: flex;
}

body {
  background: #1fa3ff;
  color: #fff;
}
.explainer-block {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 20px;
}

.explainer-block__icon {
  height: 100px;
  margin-bottom: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.explainer-class {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_class_icon.png?alt=media&token=472289ef-0f2e-42c4-a6de-c8127b3bf0e3");
}

.explainer-find {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_findteachers_icon.png?alt=media&token=ce76987f-a016-4be4-aba9-5fb3ca705f2f");
}

.explainer-connect {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_connected_icon.png?alt=media&token=79d44536-a4d6-416d-913d-7245e58a7da8");
}

.explainer-paid {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_getpaid_icon.png?alt=media&token=05cd9870-7da6-430b-ab6a-cf6cf9190a2a");
}

.explainer-share {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_share_megaphone_icon.png?alt=media&token=9685c1b1-21fe-4ca1-8814-20e5a8e79b83");
}

.center-modal div[role="document"] {
  /* height: fit-content; */
  margin: 0 auto;
  /* outline-style: none; */
}

.center-modal {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.icon-and-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-wrapper {
  text-align: center;
  background: #0e81ee;
  /* padding: 0px 10px; */
  margin: 50px auto;
  border-radius: 5px;
  overflow-y: auto;
  width: 30rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(90vh - 2rem);
  position: relative;
}

.custom-modal {
  position: fixed; /* Stay in place */
  z-index: 2000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-upgrade-wrapper {
  text-align: center;
  margin: 20px auto;
  top: 10%;
  border-radius: 15px;
  overflow-y: auto;
  width: 30rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  position: relative;
}

.custom-modal-wrapper {
  margin: 50px auto;
  border-radius: 15px;
  overflow-y: auto;
  width: 30rem;
}

@media only screen and (max-width: 530px) {
  .custom-modal-wrapper {
    width: 80%;
  }
}

.error-boundary {
  position: absolute;
  left: 0;
  right: 0;
  background: white;
  color: #0e81ee;
  height: 76%;

  /* margin-bottom: 0; */
}

/*Main Gif-Loader*/
.parentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image {
  background: url("https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/assets%2FMainGifLoader.gif?alt=media&token=50d8d236-874c-47e6-8a11-4f0c2fb6e966")
    center center;
  background-color: #e5e5e5;
  background-size: auto 100%;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  border-radius: 1000px;
  width: 150px;
  height: 150px;
}

#fadeIn {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;
  animation: fadein 5s;
  -moz-animation: fadein 5s; /* Firefox */
  -webkit-animation: fadein 5s; /* Safari and Chrome */
  -o-animation: fadein 5s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.glow-effect {
  /* margin: 150px auto; */
  width: 300px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 10px;
  position: relative;
  background: #1fa3ff;
}

.load .cta-item {
  position: absolute;
  top: 28px;
  left: 0;
  color: white;
  background: #1fa3ff;
  text-shadow: -1px -1px #fff;
}

.glow-effect:before {
  display: inline-block;
  margin: auto;
  position: absolute;
  content: "";
  width: 300px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
  filter: blur(16px);
}

.glow-effect:before {
  z-index: -200;
  -webkit-animation: inner-ripple 700ms linear infinite;
  -moz-animation: inner-ripple 700ms linear infinite;
  animation: inner-ripple 700ms linear infinite;
}

@keyframes inner-ripple {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  30% {
    transform: scale(1.1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes inner-ripple {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  30% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@-moz-keyframes inner-ripple {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  30% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.cta-add-a-class {
  width: 100%;
  height: 185px;
  background: url("https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Fcta_add_a_class.gif?alt=media&token=ab52d2e7-9bb1-403b-a501-270d801817c7")
    center;
  /* transform: scale(0.9); */
  background-size: auto 185px;
  background-repeat: no-repeat;
  /* overflow: hidden; */
}

.premium-upgrade-container {
  /* position: absolute; */
  /* left: 0; */
  /* right: 0; */
  background: white;
  /* top:0; */
  /* bottom: 0; */
  /* justify-content: center; */
  /* align-items: center; */
  /* display: flex; */
  /* color: #0e81ee; */
  /* height: 100%; */
  /* margin: 0; */
}
.error-boundary-container {
  margin-top: 50px;
  text-align: start;
}

.error-boundary-image {
  width: 288px;
  height: 298px;
  /* margin-top: -20px; */
}

/* .error-boundary-text {
  margin-top: -40px;
} */

.column-reverse-check {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (min-width: 375px) {
  .error-boundary-container {
    /* margin-left: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .error-boundary-image {
    width: 288px;
    margin-left: 5px;
  }
  .error-boundary {
    height: 76%;
  }
}
.pin-message-view {
  width: 65%;
}
@media only screen and (max-width: 959px) {
  .chat-mobile-view {
    display: none;
  }
  .pin-message-view {
    width: 100%;
  }
}

@media only screen and (min-width: 959px) {
  .tenor-gif {
    max-width: 300px;
  }
  .hide-border {
    border-left: 4px solid #cccccc;
  }
  .hide-back-arrow {
    display: none;
  }
}

@media only screen and (max-width: 720px) and (min-width: 375px) {
  .error-boundary {
    height: 81.5%;
  }
}

@media only screen and (min-width: 720px) and (max-width: 1000px) {
  .error-boundary {
    height: 80.5%;
  }
}

@media only screen and (max-width: 375px) {
  .error-boundary-container {
    margin: 20px;
    text-align: start;
  }

  .error-boundary-image {
    width: 168px;
    height: 174px;
    margin-top: 32px;
  }
  .error-boundary {
    height: 82%;
  }
  .error-boundary-text {
    margin-top: 30px;
  }
}

.edit-item-wrapper {
  margin: 5px 0 20px 0;
}

#App {
  text-align: center;
  /* font-family: 'Gill Sans light', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  opacity: 0;
  transition: opacity 2s ease-in 0.01s;
  margin: 0;
}

.App-logo {
  width: 40px;
  display: inline-block;
}

.nav-direction-holder {
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}

.profile-photo-navbar {
  width: 60px;
  height: 60px;
  padding: 0px;
  border-radius: 50%;
}

.btn-custom {
  background-color: transparent;
  border-width: 1px;
  border-color: #fff;
  color: #fff;
}

.page-title {
  margin: 25px auto !important;
}

.yogi-class-sub {
  background-color: yellow;
}

.yogi-photo {
  box-shadow: 0 2px 2px 2px #00000077;
}

#footer {
  /* min-height: 0; */
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 780px) and (min-width: 280px) {
  .footerLine {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    /* justify-content: flex-start; */
    /* align-items: baseline; */
    /* margin: '15px 0 15px 0'; */
    /* padding: '25px 0 25px 0'; */
  }
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff77;
  opacity: 1;
  /* Firefox */
}

.touchable-element {
  cursor: pointer;
  /* box-shadow: 0px 2px 2px 0px #00000077; */
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0px 2px 2px 0px #00000077;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1fa3ff;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1fa3ff;
}

/*Learn More page*/

.learn-more-box {
  box-shadow: 0px 5px 25px 0px #00000077;
  border-radius: 20px;
  width: 268px;
  margin: 0px auto 80px auto;
}

.learn-more-box--padded {
  padding: 20px;
}

.learn-more-box img.top-half-image {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 268px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
}

.learn-more-box img.in-app-screenshot {
  border-radius: 20px;
  margin: 20px auto;
}

.learn-more-box .bottom-half-wrapper {
  padding: 15px;
  padding-top: 25px;
}

/* From Calendar Options */

.calendar-option-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.calendar-option-wrapper .touchable-element {
  width: 20%;
  display: inline-block;
  border: 0px solid black;
  text-align: center;
}

/* Algolia */
.ais-Pagination-list {
  justify-content: center;
}

.ais-Pagination-item {
  padding-right: 5px;
}

.ais-SearchBox-reset svg,
.ais-SearchBox-submit svg {
  fill: rgba(0, 0, 0, 0.66);
  /* display:none; */
}

.ais-SearchBox {
  border: 0px orange solid;
  justify-content: center;
  align-items: center;
}

.ais-SearchBox-form {
  border: 0px green solid;
  background: #696969;
  border-radius: 20px;
  height: 60px;
  display: flex;
  padding: 0 15px;
}

.ais-SearchBox-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: -webkit-focus-ring-color auto 0px;
}

.ais-SearchBox-input {
  background: rgba(0, 0, 0, 0);
  color: white;
  font-size: 19px;
  border-width: 0;
  flex: 1;
}

.ais-SearchBox-input::placeholder,
.ais-SearchBox-input::-moz-placeholder,
.ais-SearchBox-input::-webkit-input-placeholder {
  font-weight: 100;
}

.ais-Stats {
  color: #1fa3ff;
  font-size: 0.75rem;
}

.ais-Panel-header {
  margin: 20px auto 10px auto;
  /* Teachers: */
  font-size: 18px;
  color: rgba(30, 163, 255, 0.6);
  letter-spacing: 0.19px;
  text-align: center;
}

/* login and singup */

.auth-social-cta {
  margin-top: 20px !important;
  cursor: pointer;
}

.auth-social-cta__google img,
.auth-social-cta__apple img {
  max-width: 220px;
}

.auth-social-cta__instagram {
  border-radius: 5px;
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  flex-direction: row;
  display: inline-flex;
  font-size: 1.125rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
}

/* .auth-social-cta__instagram {} */

.auth-social-cta .social-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

/* stripe elements */

label.stripe-po {
  font-weight: 300;
  letter-spacing: 0.025em;
}

form.stripe-po {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 0px solid #e6ebf1;
}

input.stripe-po,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input.stripe-po::placeholder {
  color: #aab7c4;
}

input.stripe-po:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.donate-preset-amount {
  width: 40px;
  height: 30px;
  border-radius: 50%;
  border: 1px #fff solid;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.donate-preset-amount__currency-marker {
  font-size: 0.75rem;
  position: relative;
  top: -10px;
}

.lineDivide h3 {
  display: flex;
  flex-direction: row;
  color: #828282;
}
.lineDivide h3:before,
.lineDivide h3:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #e0e0e0;
  margin: auto;
}
.lineDivide h3:before {
  margin-right: 10px;
}
.lineDivide h3:after {
  margin-left: 10px;
}
