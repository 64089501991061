.group-chat > * .ais-SearchBox-form {
    background-color: transparent;
    border-bottom: 1px solid #E0E0E0;
    margin-top: 8px;
    border-radius: 0px;
}

.group-chat > * .ais-SearchBox-input::placeholder { 
    color: #828282;  
}

.group-chat > * .ais-SearchBox-input{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
}